import React from 'react';
import SmartLinksOperatorFilter from "./SmartLinksOperatorFilter";
import SmartLinksCountryFilter from "./SmartLinksCountryFilter";
import SmartLinksCategoryFilter from "./SmartLinksCategoryFilter";

export default function PartnerSmartLinkFilters(props) {
    const {filters, resetAllFilters, onSelectFilterValue, smartLinks, inputStyle} = props;

    return (
        <>
            <SmartLinksOperatorFilter smartLinks={smartLinks}
                                      onSelectOperator={(value) => onSelectFilterValue(value, "selectedOperators")}
                                      selectedOperators={filters.selectedOperators}
                                      inputStyle={inputStyle}/>
            <SmartLinksCountryFilter smartLinks={smartLinks}
                                     onSelectCountry={(value) => onSelectFilterValue(value, "selectedCountries")}
                                     selectedCountries={filters.selectedCountries}
                                     inputStyle={inputStyle}/>
            <SmartLinksCategoryFilter smartLinks={smartLinks}
                                      onSelectCategory={(value) => onSelectFilterValue(value, "selectedCategories")}
                                      selectedCategories={filters.selectedCategories}
                                      inputStyle={inputStyle}/>
            <div className="circle-btn-group">
                <button className="circle-btn" title="Очистить" onClick={resetAllFilters}><i
                    className="ti ti-close"/>
                </button>
            </div>
        </>
    )

}

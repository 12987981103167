import React, {useEffect, useState} from "react";
import {CircularProgress, IconButton, Popover} from "@material-ui/core";
import IosCheckbox from "../../../common/checkboxes/IosCheckbox";
import {ALL_OFFERS} from "../../../../utils/constants";
import {connect, useSelector} from "react-redux";
import MaterialUISelect from "../../../common/selects/MaterialUISelect";
import {availableBillingTypesData} from "../../../../utils/offerUtils";
import Done from '@material-ui/icons/DoneOutlined';
import Cancel from '@material-ui/icons/ClearOutlined';
import {returnErrors} from "../../../../actions/errorActions";
import {createSmartLinks, loadPartnerSmartLinks} from "../../../../actions/partnerSmartLinkActions";
import {addTimezoneOffset} from "../../../../utils/dateUtils";
import {loadPartnerProjects} from "../../../../actions/projectsActions";
import MaterialUIMultiSelect from "../../../common/selects/MaterialUIMultiSelect";
import {loadActiveOperators} from "../../../../actions/dictionaryActions";

function SmartLinkCreationPopover({onSelectTab, categories, projects, loadPartnerProjects, loadActiveOperators, allOffers, operators, returnErrors, createSmartLinks, loadPartnerSmartLinks,inputStyle}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const billingTypes = availableBillingTypesData;

    const [autoRotation, setAutoRotation] = useState(true);
    const [category, setCategory] = useState(null);
    const [availableOperators, setAvailableOperators] = useState(null);
    const [availableCategories, setAvailableCategories] = useState(null);
    const [billingType, setBillingType] = useState(null);
    const [project, setProject] = useState(null);
    const [selectedOperators, setSelectedOperators] = useState(null);
    const [maxBenefit, setMaxBenefit] = useState(false);

    const saving = useSelector(state => state.partnerSmartLinks.saving);

    useEffect(() => {
        loadPartnerProjects();
        loadActiveOperators();
    }, []);

    useEffect(() => {
        if (!allOffers || !categories) {
            return;
        }
        const availableCategories = categories.filter(category => allOffers.some(offer => offer.category?.id === category.id));
        setAvailableCategories(availableCategories);
    }, [categories, allOffers]);

    useEffect(() => {
        if (!allOffers || !operators || operators.length === 0) {
            return;
        }
        const availableOperators = operators.filter(operator => allOffers.some(offer => offer.operatorId === operator.id));
        setAvailableOperators(availableOperators);
    }, [operators, allOffers]);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const redirectToOffers = () => {
        onSelectTab(ALL_OFFERS);
    };

    const createSmartLink = () => {
        if (!category || !billingType || saving) {
            return;
        }
        const availableOffers = allOffers.filter(offer => offer.category?.id === category.id && offer.offerIncomes.some(income => income.billingType === billingType.type));
        if (availableOffers.length === 0) {
            const error = {response: {status: 400, data: {details: 'Подходящие офферы не найдены'}}};
            returnErrors(error);
        }
        if (selectedOperators == null) {
            const error = {response: {status: 400, data: {details: 'Операторы не выбраны'}}};
            returnErrors(error);
        }
        const d = addTimezoneOffset(new Date());
        let dateString = d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear() + "_" +
            d.getHours() + ":" + d.getMinutes();
        const smartLinkOffers = selectedOperators.map(operator => {return {
            operatorId: operator.id,
            offerId: -1,
            billingType: billingType.type,
            category: category,
        }});
        const smartLinkData = {
            name: dateString + '-' + category.name + '-' + project.dominicPartnerId + '-' + project.sourceId + '-Авто',
            projectId: project.id,
            offerCategoryId: category.id,
            autoRotation: true,
            maxBenefit: maxBenefit,
            smartLinkOffers: smartLinkOffers
        };
        createSmartLinks([smartLinkData], null, () => loadPartnerSmartLinks())
    };

    const popover = () => {
        return (
            <Popover id={id}
                     open={open}
                     anchorEl={anchorEl}
                     onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'right',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'right',
                     }}
            >
                <div style={{padding: "10px", textAlign: "center"}}>Новый смартлинк</div>
                <div style={{padding: "20px", minWidth: "200px", maxWidth: "300px"}}>

                    <div className='modal-checkbox'>
                        <IosCheckbox label='Автоматический' labelClassName='font-14 margin-right10' isChecked={autoRotation}
                                     onClickFunction={() => {
                                         setAutoRotation(!autoRotation)
                                     }}/>
                    </div>
                    {autoRotation &&
                    <div >
                        <div  className='modal-checkbox' title="Абоненты с неподходящими IP адресами будут перенаправлены на специальные офферы">
                            <IosCheckbox label='Максимум пользы' labelClassName='font-14  margin-right10' isChecked={maxBenefit}
                                         onClickFunction={() => {
                                             setMaxBenefit(!maxBenefit)
                                         }}/>
                        </div>
                        <MaterialUIMultiSelect dropdownData={availableOperators} values={selectedOperators} dataField='id'
                                               displayedField='name' label='Операторы' placeholder='-'
                                               onSelect={setSelectedOperators} styles={{root: {padding: "0px"}}}/>
                        <MaterialUISelect dropdownData={projects}
                                          displayedFields={['sourceId', 'name']} dataField='id' label="Поток"
                                          selectedValue={project} onSelectCallbackFunction={setProject}
                                          isSearchable={true}/>
                        <MaterialUISelect dropdownData={availableCategories} selectedValue={category}
                                          dataField='id' displayedField='name' label='Тематика' placeholder='-'
                                          onSelectCallbackFunction={setCategory}/>
                        <MaterialUISelect dropdownData={billingTypes} selectedValue={billingType}
                                          dataField='type' displayedField='fullName' label='Тип выплаты' placeholder='-'
                                          onSelectCallbackFunction={setBillingType}/>
                    </div>}
                    <div>
                        {autoRotation && <div className="font-12 font-weight-bold">
                            Офферы будут подбираться автоматически по IP адресу абонента и вашим показателям CR </div>}
                        {maxBenefit && <div className="font-12 font-weight-bold">Абоненты с неподходящими IP адресами будут перенаправлены на специальные офферы</div>}
                        {!autoRotation &&
                        <p className="font-12 font-weight-bold">Для создания смартлинка выберите необходимые
                            офферы на вкладке ВСЕ</p>}
                    </div>
                    <br/>
                    <div className="text-right">
                        <IconButton disableFocusRipple size="medium"
                                    onClick={autoRotation ? createSmartLink : redirectToOffers}
                                    variant="contained"
                                    title={autoRotation ? "Создать смартлинк" : "Выбрать офферы"}>
                            {saving
                                ? <CircularProgress style={{color: 'rgb(102, 102, 102)', height:'20px', width: '20px'}}/>
                                : <Done fontSize="medium"/>
                            }

                        </IconButton>
                        <IconButton disableFocusRipple size="medium" onClick={handleClose} variant="contained"
                                    title="Отмена">
                            <Cancel fontSize="medium"/>
                        </IconButton>

                    </div>
                </div>
            </Popover>
        )
    };

    return (
        <>
            <div onClick={handleClick}>
                <div className="btn btn-outline-secondary">СОЗДАТЬ</div>
            </div>
            {anchorEl && popover()}
        </>
    )
}

function mapStateToProps(state) {
    return {
        categories: state.filterDataReducer.filterData.categories,
        allOffers: state.partnerOffers.offers,
        projects: state.projects.projects,
        operators: state.filterDataReducer.filterData.operators,
    }
}

export default connect(mapStateToProps, {
    returnErrors,
    createSmartLinks,
    loadPartnerSmartLinks,
    loadPartnerProjects,
    loadActiveOperators,
})(SmartLinkCreationPopover);

import React, {useState, useRef, Fragment} from "react";
import {getBillingTypeName} from "../../../../utils/offerUtils";
import {useDispatch, useSelector} from "react-redux";
import {
    CHANGE_SMARTLINK_FIELD,
    CHANGE_SMARTLINK_OFFER_PROBABILITY,
    EXCLUDE_OFFER_FROM_SMARTLINK
} from "../../../../actions/actionTypes";
import SimpleTable from "../../../common/tables/SimpleTable";
import {LinkCopyPopup} from "../../../common/linkCopyPopup/LinkCopyPopup";
import OperatorImage from "../../../common/images/OperatorImage";
import {replaceDomain} from "../../../../utils/urlUtils";
import OfferLinkInfo from "../OfferLinkInfo";
import CountryImage from "../../../common/images/CountryImage";



export default function SmartLinkEditingTable({smartLinkUrl, onDelete, onChangeTableState, selectedDomain}) {
    const smartLink = useSelector(state => state.partnerSmartLinks.editableSmartLink);
    const canBeEdited = smartLink && smartLink.status !== "GLOBAL";
    const smartLinkOffers = smartLink && smartLink.smartLinkOffers ? smartLink.smartLinkOffers : [];
    const dispatch = useDispatch();
    const [timeoutState, setTimeoutState] = useState({
        timeout: 0
    });
    const childRef = useRef();

    function changeSmartLinkField(fieldName, value) {
        if (timeoutState.timeout) {
            clearTimeout(timeoutState.timeout);
        }
        setTimeoutState({
            timeout: setTimeout(() => {
                dispatch({type: CHANGE_SMARTLINK_FIELD, payload: {id: smartLink.id, fieldName: fieldName, value: value}});
            }, 500)
        });
    }

    function excludeOfferFromSmartLink(smartLinkOffer) {
        if (smartLink.id && smartLink.smartLinkOffers.length === 1) {
            onDelete(smartLink);
        } else {
            dispatch({type: EXCLUDE_OFFER_FROM_SMARTLINK, payload: smartLinkOffer});
        }
        onChangeTableState();
    }

    function setSmartLinkOfferProbability(e, offerId) {
        let val = e.target.value;
        val = val.replace(/[^0-9.]+/g, '');
        val = (val || val !== '') ? parseInt(val) : 0;
        if (val >= 0 && val <= 100) {
            dispatch({type: CHANGE_SMARTLINK_OFFER_PROBABILITY, payload: {offerId: offerId, probability: val}});
        }
        onChangeTableState();
    }

    const smartLinkColumns = () => {
        return [
            {
                name: "Название смартлинка",
                key: "name",
                getBodyCellValue: smartLink => canBeEdited ? <input type="text" placeholder="Название смартлинка"
                                                                    className="form-control"
                                                                    defaultValue={smartLink ? smartLink.name : ''}
                                                                    onChange={e => {
                                                                        changeSmartLinkField("name", e.target.value)
                                                                    }}/> : smartLink ? smartLink.name : '',
                headerCellStyle: {minWidth: "300px", width: "300px"}
            },
            {
                name: "ОСС",
                key: "oss",
                getBodyCellValue: smartLink => smartLink.operatorImgFileNames?.map(fileName =>
                    <Fragment key={fileName}><OperatorImage fileName={fileName}/></Fragment>),
                headerCellStyle: {minWidth: "150px", width: "150px"},
                bodyCellClassName: "td-operator inline"
            },
            {
                name: "Страны",
                key: "countries",
                getBodyCellValue: smartLink => smartLink.countryImgFileNames?.map(fileName =>
                    <Fragment key={fileName}><CountryImage fileName={fileName}/></Fragment>),
                headerCellStyle: {minWidth: "150px", width: "150px"},
                bodyCellClassName: "td-operator inline"
            },
            {
                name: "Тематика",
                key: "theme",
                getBodyCellValue: smartLink => smartLink.category.name,
                headerCellStyle: {minWidth: "150px", width: "150px"}
            },
            {
                name: "Ссылка",
                key: "link",
                getBodyCellValue: smartLink => <span className="link" onClick={() =>
                    childRef.current.handleClickCopy(replaceDomain(smartLinkUrl, selectedDomain && selectedDomain.cname))}>
                        <input readOnly={true} type="text"
                               placeholder="ссылка появится после нажатия на 'Получить ссылки'"
                               className="linkToCopy copyClick form-control form-control-sm"
                               value={replaceDomain(smartLinkUrl, selectedDomain && selectedDomain.cname)}/></span>,
            },
            {
                name: "",
                key: "buttons",
                getBodyCellValue: smartLink => canBeEdited &&
                    <i className="ti ti-close cursor" onClick={() => onDelete(smartLink)}/>,
                headerCellStyle: {minWidth: "40px", width: "40px"}
            },
        ]
    };

    const offersColumns = () => {
        return [
            {
                name: "Название",
                key: "name",
                getBodyCellValue: smartLinkOffer => smartLinkOffer.offerId + ' | ' + smartLinkOffer.offerName,
                headerCellStyle: {minWidth: "300px", width: "300px"}
            },
            {
                name: "ОСС",
                key: "oss",
                getBodyCellValue: smartLinkOffer => <OperatorImage fileName={smartLinkOffer.operatorImgFileName} title={smartLinkOffer.operatorName}/>,
                headerCellStyle: {minWidth: "150px", width: "150px"},
                bodyCellClassName: "td-operator"
            },
            {
                name: "Страна",
                key: "country",
                getBodyCellValue: smartLinkOffer => <CountryImage fileName={smartLinkOffer.countryImgFileName} title={smartLinkOffer.operatorName}/>,
                headerCellStyle: {minWidth: "150px", width: "150px"},
                bodyCellClassName: "td-operator inline"
            },
            {
                name: "Выплата",
                key: "payment",
                getBodyCellValue: smartLinkOffer => smartLinkOffer.currentIncome
                    ? <><span>{smartLinkOffer.currentIncome}</span><br/><span style={{"fontSize": "10px"}}>
                              {getBillingTypeName(smartLinkOffer.billingType, false)}</span></> : "-",
                headerCellStyle: {minWidth: "90px", width: "90px"}
            },
            {
                name: "Вес",
                key: "weight",
                getBodyCellValue: smartLinkOffer => canBeEdited ? <input type="text"
                                                                         defaultValue={smartLinkOffer.probability}
                                                                         value={smartLinkOffer.probability}
                                                                         className="form-control control-sm-3 has-error"
                                                                         style={{width: "50px"}}
                                                                         title="Приоритет отображения оффера. 100 - максимальный, 0 - минимальный"
                                                                         onChange={(e) => {
                                                                             setSmartLinkOfferProbability(e, smartLinkOffer.offerId);
                                                                         }}
                                                                         disabled={smartLink.autoProbability}/>
                                                                         : smartLinkOffer.probability,
            },
            {
                name: "",
                key: "buttons",
                getBodyCellValue: smartLinkOffer => canBeEdited &&
                    <i onClick={() => excludeOfferFromSmartLink(smartLinkOffer)} className="ti ti-close cursor"/>,
                headerCellStyle: {minWidth: "40px", width: "40px"}
            },
        ]
    };

    return (
        <>
            {
                smartLink
                &&
                <div key={"1"}>
                    {<p className="title-smart">{"Смартлинк" + (smartLink.autoRotation ? " (Авто)" : "")}</p>}
                    <div className="responsive-block" id="fullWidth"
                         style={{overflowX: 'auto', overflowY: 'hidden'}}>
                        <SimpleTable columns={smartLinkColumns()} data={[smartLink]} bodyRowKey="id"/>
                    </div>
                </div>
            }

            {smartLinkOffers && smartLinkOffers.length > 0 && !smartLink?.autoRotation &&
            <div key={"2"}>
                <p className="title-smart">Набор офферов</p>
                <div className="responsive-block" id="fullWidth" style={{overflowX: 'auto', overflowY: 'hidden'}}>
                    <SimpleTable columns={offersColumns()} data={smartLinkOffers} bodyRowKey="offerId"/>
                </div>
            </div>
            }
            <OfferLinkInfo/>
            <br/>
            <br/>
            <LinkCopyPopup ref={childRef}/>
        </>
    );
}

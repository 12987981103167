import React from "react";
import IosCheckbox from "../../../common/checkboxes/IosCheckbox";
import {CHANGE_SMARTLINK_FIELD} from "../../../../actions/actionTypes";
import {useDispatch, useSelector} from "react-redux";

export default function SmartLinkEditingCheckbox({name, label, title, width='180px'}) {

    const dispatch = useDispatch();

    const editableSmartLink = useSelector(state => state.partnerSmartLinks.editableSmartLink);

    const toggleCheckbox = () => {
        dispatch({type: CHANGE_SMARTLINK_FIELD, payload: {id: editableSmartLink.id, fieldName: name, value: !editableSmartLink[name]}});
    };

    if (!editableSmartLink) {
        return <></>;
    }


    return (
        <>
            <div className="custom-mselect" style={{width: width, paddingTop: '20px'}} title={title}>
                <IosCheckbox labelClassName='font-14' labelStyle={{marginRight: '10px'}} isChecked={editableSmartLink && editableSmartLink[name]}
                             onClickFunction={toggleCheckbox} label={label}/>
            </div>
        </>
    );

}
import {
    CREATE_SMARTLINKS,
    DELETE_SMARTLINK,
    EDIT_SMARTLINK,
    PARTNER_SMARTLINK_LOADED,
    PARTNER_SMARTLINK_LOADING,
    PARTNER_SMARTLINKS_LOADED,
    PARTNER_SMARTLINKS_LOADING,
    SAVE_SMARTLINKS, SMARTLINK_ACTION_ERROR, SMARTLINK_URL_LOADED, SMARTLINKS_SAVING
} from "./actionTypes";
import {getPartnerId} from "../utils/authUtils";
import axios from "axios";
import {getConfig} from "./authActions";
import {returnAllErrorsAsPopup, returnErrors} from "./errorActions";
import {getSuccess} from "./successActions";
import {searchByString} from "../utils/searchUtils";


export const loadPartnerSmartLinks = () => (dispatch, getState) => {
    dispatch({type: PARTNER_SMARTLINKS_LOADING});

    let partnerId = getPartnerId() ? getPartnerId() : '';

    axios
        .get(process.env.REACT_APP_API_URL + '/smartLinks?partnerId=' + partnerId, getConfig(getState))
        .then(res =>
            dispatch({
                type: PARTNER_SMARTLINKS_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};


export const saveSmartLink = (smartLink, onSuccessCallback) => (dispatch, getState) => {
    dispatch({type: SMARTLINKS_SAVING});
    smartLink.offerCategoryId = smartLink.category.id;
    const smartLinks = [smartLink];
    let partnerId = getPartnerId() ? getPartnerId() : '';
    const success = {success: "Смартлинк сохранён"};
    axios
        .post(process.env.REACT_APP_API_URL + '/smartLinks?partnerId=' + partnerId, smartLinks, getConfig(getState))
        .then(res => {
                dispatch({
                    type: SAVE_SMARTLINKS,
                    payload: res.data
                });
            }

        )
        .then(() => {
            if (onSuccessCallback) {
                onSuccessCallback();
            }
        })
        .then(() => {dispatch(getSuccess(success))})
        .catch(err => {
            dispatch(returnAllErrorsAsPopup(err));
        });
};

export const createSmartLinks = (smartLinksTemplates, macros, callback) => (dispatch, getState) => {
    dispatch({type: SMARTLINKS_SAVING});
    let partnerId = getPartnerId() ? getPartnerId() : '';
    const success = {success: smartLinksTemplates.length === 1 ? "Смартлинк сохранён." : "Смартлинки сохранены."};
    smartLinksTemplates = smartLinksTemplates.map(template => {return {...template, macros: macros}});
    let result;
    axios
        .post(process.env.REACT_APP_API_URL + '/smartLinks?partnerId=' + partnerId, smartLinksTemplates, getConfig(getState))
        .then(res => {
                dispatch({
                    type: CREATE_SMARTLINKS,
                    payload: result = res.data
                });
            }
        )
        .then(() => success && callback && callback(result))
        .then(() => {dispatch(getSuccess(success))})
        .catch(err => {
            dispatch({type: SMARTLINK_ACTION_ERROR});
            dispatch(returnAllErrorsAsPopup(err));
        });
};

export const deleteSmartLink = (smartLink, callbackFunction) => (dispatch, getState) => {

    const smartLinkId = smartLink.id;
    let partnerId = getPartnerId() ? getPartnerId() : '';

    const success = {success: "Смартлинк удалён"};
    axios
        .delete(process.env.REACT_APP_API_URL + '/smartLinks?id=' + smartLinkId+ '&partnerId=' + partnerId, getConfig(getState))
        .then(res => {
                dispatch({
                    type: DELETE_SMARTLINK,
                    payload: smartLink
                });
            }

        )
        .then(() => {dispatch(loadPartnerSmartLinks())})
        .then(() => success && callbackFunction && callbackFunction(smartLink))
        .then(() => {dispatch(getSuccess(success))})
        .catch(err => {
            dispatch(returnAllErrorsAsPopup(err));
        });
};

export const loadPartnerSmartLink = (smartLinkId) => (dispatch, getState) => {
    dispatch({type: PARTNER_SMARTLINK_LOADING});
    let partnerId = getPartnerId() ? getPartnerId() : '';

    axios
        .get(process.env.REACT_APP_API_URL + '/smartLinks/' + smartLinkId+ '?partnerId=' + partnerId, getConfig(getState))
        .then(res =>
            dispatch({
                type: PARTNER_SMARTLINK_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const loadSmartLinkUrl = (smartLinkId, projectId) => (dispatch, getState) => {
    let partnerId = getPartnerId() ? getPartnerId() : '';

    axios
        .get(process.env.REACT_APP_API_URL + '/smartLinks/' + smartLinkId + '/link?partnerId=' + partnerId + '&projectId=' + projectId,
            getConfig(getState))
        .then(res =>
            dispatch({
                type: SMARTLINK_URL_LOADED,
                payload: res.data
            })
        )
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const editPartnerSmartLink = (smartLinkId) => (dispatch, getState) => {
    loadPartnerSmartLink(smartLinkId);
    dispatch({type: EDIT_SMARTLINK});
};

export const filterSmartLinksBySearchInput = (smartLinks, inputSearchValue) => {
    return searchByString(smartLinks, ["id", "name"], inputSearchValue);
};

export const filterSmartLinksByOperators = (smartLinks, operators) => {
    if (!smartLinks || smartLinks.length === 0 || !operators || operators.length === 0) {
        return smartLinks;
    }
    const operatorValues = operators.map(operator => operator.id);
    return smartLinks.filter(smartLink => operatorValues.some(operatorValue => smartLink.operatorIds.includes(operatorValue)));
};

export const filterSmartLinksByCountries = (smartLinks, countries) => {
    if (!smartLinks || smartLinks.length === 0 || !countries || countries.length === 0) {
        return smartLinks;
    }
    const countryValues = countries.map(country => country.id);
    return smartLinks.filter(smartLink => countryValues.some(countryValue => smartLink.countryIds.includes(countryValue)));
};

export const filterSmartLinksByCategories = (smartLinks, categories) => {
    if (!smartLinks || smartLinks.length === 0 || !categories || categories.length === 0) {
        return smartLinks;
    }
    const categoryIds = categories.map(category => category.id);
    return smartLinks.filter(smartLink => categoryIds.includes(smartLink.category.id));
};
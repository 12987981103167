import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {CHANGE_SMARTLINK_FIELD} from "../../../../actions/actionTypes";

export default function SmartLinkEditingInput({name, type="text", placeholder, style }) {
    const dispatch = useDispatch();

    const editableSmartLink = useSelector(state => state.partnerSmartLinks.editableSmartLink);

    const handleChange = (e) => {
        dispatch({type: CHANGE_SMARTLINK_FIELD, payload: {id: editableSmartLink.id, fieldName: name, value: e.target.value}});
    };

    if (!editableSmartLink) {
        return <></>;
    }


    return (
        <input type={type} placeholder={placeholder}
               className="form-control"
               aria-placeholder=''
               defaultValue={editableSmartLink ? editableSmartLink[name] : ''}
               onChange={handleChange} style={style}/>
    );
}